<template>
  <div>
    <span class="manager-name font-weight-bold">
      {{ manager.name }}
    </span>
    <br>
    <div class="manager-email">
      <span
        v-t="'tips.productDetails.email'"
        class="mr-2"
      />
      <a :href="`mailto: ${manager.email}`">
        {{ manager.email }}
      </a>
    </div>
    <div>
      <span
        v-t="'tips.productDetails.tel'"
        class="mr-2"
      />
      {{ manager.phone }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('client', ['clients']),
    manager () {
      return this.clients[this.$route.params.clientId] && this.clients[this.$route.params.clientId].manager
        ? this.clients[this.$route.params.clientId].manager
        : {}
    }
  }
}
</script>

<style scoped>
.manager-email {
  word-break: break-word;
}

.manager-name {
  text-decoration: underline;
}
</style>
